<template>
  <el-card class="work-list-page page-container">
    <el-form slot="header" :inline="true" size="small">
      <el-form-item label="工单名称" class="input-with-search">
        <el-input v-model="work.name" clearable @clear="getMyApprovalTaskList({ page: 1, size })">
          <el-button slot="append" icon="el-icon-search" @click="handleSelect(work)">搜索</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="工单状态">
        <el-select v-model="work.status" @change="handleChangeApprovalStatus(work)">
          <el-option v-for="status in itemStatus" :value="status.value" :label="status.label" :key="status.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button size="mini" type="primary" @click="dialog = true" :disabled="!isPermit">新建工单</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="workList" :height="tHeight" :tree-props="{ children: 'childTasks' }">
      <el-table-column label="工单编号" prop="taskId" align="center" width="200"></el-table-column>
      <el-table-column label="工单名称" prop="taskName">
        <template slot-scope="scope">
          <el-link type="primary" @click="handleAccraditationDetails(scope.row.taskId)">{{ scope.row.taskName }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="工单状态" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" :type="scope.row.taskStatus | findTagType">{{ scope.row.taskStatus | tstatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="审批时间">
        <template slot-scope="scope">{{ scope.row.approvalTime ? $moment(scope.row.approvalTime).format('YYYY-MM-DD HH:mm:ss') : '' }}</template>
      </el-table-column>
      <el-table-column label="审批意见" prop="approvalOpinions"></el-table-column>
      <el-table-column label="审批人用户名" prop="approvalUserName"></el-table-column>
      <el-table-column label="创建时间" align="center" width="160">
        <template slot-scope="scope">{{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss') }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" :disabled="scope.row.taskStatus !== 1 ? true : false" @click="handleChangeApproval(scope.row)">修改</el-button>
          <el-button type="text" size="small" :disabled="scope.row.taskStatus !== 1 ? true : false" @click="undoApproval(scope.row.taskId)" style="color: #f40">撤销</el-button>
        </template>
      </el-table-column>
    </el-table>
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
    <el-dialog :close-on-click-modal="false" :visible.sync="dialog" title="工单信息" @close="handleCloseDailog('AccraditationForm')">
      <el-form ref="AccraditationForm" :model="accraditation" :label-width="labelWidth" :label-position="labelPosition" :rules="accraditationRules">
        <el-form-item label="角色" prop="roles">
          <el-select style="width: 280px" v-model="accraditation.roles" multiple collapse-tags :multiple-limit="1" @change="settingOperationRights" v-if="!isSky" @remove-tag="handleRemoveRole">
            <el-option v-for="role in userRoles" :value="role.roleId" :label="role.roleName" :key="role.roleName"></el-option>
          </el-select>
          <el-tag size="small" v-else>管理员</el-tag>
        </el-form-item>
        <el-form-item label="工单标题" prop="taskName" v-if="false">
          <el-input v-model="accraditation.taskName" style="width: 280px"></el-input>
        </el-form-item>
        <el-form-item label="手机号" v-if="false">
          <el-input v-model="accraditation.approvalUserMobile" style="width: 280px"></el-input>
        </el-form-item>
        <el-form-item label="企业" prop="cpIds" v-if="userRoleCors.length > 1">
          <el-select style="width: 280px" v-bind:multiple-limit="corMultipleLimit" multiple collapse-tags v-model="accraditation.cpIds" @change="handleCorporations(accraditation.cpIds)" @remove-tag="handleRemoveCpId">
            <el-option v-for="corporation in userRoleCors" :key="corporation.cpId" :label="corporation.cpName" :value="corporation.cpId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目" prop="pids" v-if="isEditProOptions">
          <el-select style="width: 280px" v-bind:multiple-limit="proMultipleLimit" :disabled="accraditation.roles.length ? false : true" multiple collapse-tags v-model="accraditation.pids" @change="handleProjects(accraditation.pids)" @remove-tag="handleRemovePid">
            <el-option v-for="project in userRolePros" :key="project.pId" :label="project.pName" :value="project.pId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="站点" prop="stationIds" v-if="isEditSiteOptions">
          <el-select style="width: 280px" v-bind:multiple-limit="siteMultipleLimit" :disabled="accraditation.roles.length ? false : true" multiple collapse-tags v-model="accraditation.stationIds">
            <el-option v-for="site in userRoleStations" :key="site.stId" :label="site.sName" :value="site.stId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务描述" prop="taskDescription">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" maxlength="200" show-word-limit v-model="accraditation.taskDescription"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="submitAccraditation('AccraditationForm')">确认</el-button>
          <el-button size="small" @click="resetAccraditation('AccraditationForm')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-drawer title="工单信息" size="500px" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
      <AccraditationDetails :accraditationDetails="accraditationDetails" />
    </el-drawer>
  </el-card>
</template>

<script>
import AccraditationDetails from './components/AccraditationDetails.vue';
import PaginationBar from '@/components/Pagination/index.vue';
import { myApprovalTaskList, getApprovalTaskDetail, undoMyApprovalTask, getCorporationBySelf, getProjectBycpIds, getStationsByProjects, addApprovalTask, getUserRole, updateApprovalTask } from '@/api/accraditation';
import statusInclude from '@/utils/statusCode';
import sleep from '@/utils/sleep';
import { getUserInformation, getUserCorporationsInformation } from '@/utils/auth.js';
import { checkMobile } from '@/utils/rules.js';
export default {
  name: 'WorkList',
  components: {
    AccraditationDetails,
    PaginationBar,
  },
  filters: {
    findTagType(status) {
      const tagMap = {
        1: 'info',
        2: 'warning',
        3: 'success',
        4: 'danger',
        5: '',
      };
      return tagMap[status];
    },
  },
  data() {
    return {
      tHeight: '',
      direction: 'rtl',
      labelWidth: '80px',
      labelPosition: 'left',

      corMultipleLimit: 1,
      proMultipleLimit: 1,
      siteMultipleLimit: 1,
      isPermit: false,
      dialog: false,
      drawer: false,
      isSky: false,
      isEditProOptions: true,
      isEditSiteOptions: true,
      workList: [],
      userRoleCors: [], //此用户权限下可选的企业列表
      userRolePros: [],
      userRoleStations: [],
      userRoles: [],
      cpIds: [],
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
      work: {
        name: '',
        status: 0,
      },
      itemStatus: [
        {
          value: 0,
          label: '待办',
        },
        {
          value: 1,
          label: '已办',
        },
        // {
        //   value: 1,
        //   label: '待审批',
        // },
        // {
        //   value: 2,
        //   label: '审批中',
        // },
        // {
        //   value: 3,
        //   label: '审批通过',
        // },
        // {
        //   value: 4,
        //   label: '审批驳回',
        // },
        // {
        //   value: 5,
        //   label: '审批完成',
        // },
        // {
        //   value: 6,
        //   label: '撤销',
        // },
      ],
      accraditationRules: {
        taskName: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
        mobile: [{ required: true, validator: checkMobile, trigger: 'blur' }],
        cpIds: [{ required: true, message: '请选择公司', trigger: 'change' }],
        pids: [{ required: true, message: '请选择项目', trigger: 'change' }],
        stationIds: [{ required: true, message: '请选择站点', trigger: 'change' }],
        roles: [{ required: true, message: '请选择用户角色', trigger: 'change' }],
        taskDescription: [
          { required: true, message: '请填写任务描述', trigger: 'blur' },
          { min: 1, max: 200, message: '长度在 1 到 200', trigger: 'blur' },
        ],
      },
      accraditation: {
        taskName: '',
        cpIds: [],
        pids: [],
        stationIds: [],
        roles: [],
        approvalUserMobile: '',
        taskDescription: '',
      },
      accraditationDetails: {},
    };
  },
  computed: {
    hasRole() {
      return this.accraditation.roles.legth ? true : false;
    },
  },
  created() {
    window.addEventListener('resize', this.getHeight);
    this.getHeight();
    const cp = getUserCorporationsInformation();
    this.isPermit = true;
    const { roleId, userMobile: mobile } = getUserInformation().userInformation;

    /**
     * 确认用户所绑定的企业是否是母公司
     * 1. 母公司只能选择企业 其他无法选择
     * 2. 母公司默认选中管理员权限
     * 3. 所有企业自动全选，项目，站点选项不显示
     * 4. 其他企业不展示公司
     */

    //当用户为访客时传入用户手机号
    Boolean(cp.length) &&
      this.getCorporationBySelf(roleId !== 'R0001' ? '' : mobile).then((response) => {
        this.cpIds = response.map(({ cpId }) => cpId);
        //当绑定企业为星逻子公司时，默认选中管理员权限
        if (response.length > 1) {
          this.accraditation.roles = ['R0005'];
          this.corMultipleLimit = 0;
          this.proMultipleLimit = 0;
          this.isSky = true;
        }
        this.accraditation.cpIds = JSON.parse(JSON.stringify(this.cpIds));
        this.getProjectBycpIds(this.cpIds); //请求企业下所有项目信息
        this.getRoleGroup(); //获取用户角色列表
        this.settingOperationRights(this.accraditation.roles);
      });
  },
  mounted() {
    this.getMyApprovalTaskList({ ...this.tableConfig });
  },
  methods: {
    getHeight() {
      this.tHeight = this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },

    /**
     * 校验用户角色信息 确认操作权限
     * R0001 : 访客；访客不可选择企业，但可以选择某个项目，某个站点 （项目，站点数量为1个）
     * R0002 : 操作员；操作员不可选择企业，可选择多个企业，多个站点 （项目，站点数量可多个）
     * R0003 : 项目管理员；项目管理员不可选择企业，可选择多个项目，站点无法选择（默认全选）
     * R0004 : 企业管理员；企业管理员可选择单个企业（但是绑定了某个企业也是默认选中此单个企业，所以无需选择），项目站点不可选择性操作，默认全选
     * R0005 : 管理员；管理员可选择企业（默认全部选中），项目、站点不可选择（默认全选项目下站点）
     */
    settingOperationRights(roles) {
      const [role] = JSON.parse(JSON.stringify(roles));
      this.handleRemoveRole();
      //根据角色编号设置用户操作权限
      switch (role) {
        case 'R0001':
          this.proMultipleLimit = 1;
          this.siteMultipleLimit = 1;
          this.isEditProOptions = true;
          this.isEditSiteOptions = true;
          break;
        case 'R0002':
          this.proMultipleLimit = 0;
          this.siteMultipleLimit = 0;
          this.isEditProOptions = true;
          this.isEditSiteOptions = true;
          break;
        case 'R0003':
          this.proMultipleLimit = 0;
          this.siteMultipleLimit = 0;
          this.isEditProOptions = true;
          this.isEditSiteOptions = false;
          break;
        case 'R0004':
        case 'R0005':
          this.proMultipleLimit = 0;
          this.siteMultipleLimit = 0;
          this.isEditProOptions = false;
          this.isEditSiteOptions = false;
          this.handleAdministrator(); //默认选中所有项目，站点信息
          break;
        default:
          //默认设置当移除了角色信息时
          this.proMultipleLimit = 1;
          this.siteMultipleLimit = 1;
          this.isEditProOptions = true;
          this.isEditSiteOptions = true;
          break;
      }
    },

    //修改用户工单信息
    async handleChangeApproval(row) {
      this.handleRemoveRole(row.taskId);
      if (row.roles) return this.$message('工单信息错误');
      row.roles && this.settingOperationRights(row.roles.map(({ roleId }) => roleId));
      const { code, reason, data } = await getApprovalTaskDetail(row.taskId);
      if (!statusInclude(code)) return this.$message.error(`请求失败${reason}`);
      this.accraditationDetails = data;
      this.accraditation.roles = data.roles.map(({ roleId }) => roleId);
      this.accraditationDetails.corporationInfos.forEach((c) => {
        this.accraditation.cpIds.push(c.cpId);
        c.projects.forEach((p) => {
          this.accraditation.pids.push(p.pId);
          p.stations.forEach((s) => {
            this.accraditation.stationIds.push(s.stId);
          });
        });
      });
      this.accraditation.pids.length && this.handleProjects(this.accraditation.pids);
      this.accraditation.taskDescription = data.taskDescription;
      this.isEditWorkItem = true;
      this.dialog = true;
    },

    handleCurrentChange(page) {
      this.tableConfig.page = page;
      this.getMyApprovalTaskList({ ...this.tableConfig });
    },

    handleCloseDailog(formName) {
      this.resetAccraditation(formName);
    },

    //移除角色时
    handleRemoveRole(tag) {
      if (!tag) return;
      this.accraditation.cpIds = [];
      this.accraditation.pids = [];
      this.accraditation.stationIds = [];
    },
    //移除公司时
    handleRemoveCpId(tag) {
      this.accraditation.pids = [];
      this.accraditation.stationIds = [];
    },
    //移除项目时
    handleRemovePid(tag) {
      this.accraditation.stationIds = [];
    },

    //选中管理员角色时
    async handleAdministrator() {
      //选中所有企业
      this.accraditation.cpIds = this.userRoleCors.map(({ cpId }) => cpId);

      //获取企业下项目信息
      await this.getProjectBycpIds(this.accraditation.cpIds);

      this.accraditation.pids = this.userRolePros.map(({ pId }) => pId);

      //获取项目下站点信息
      this.accraditation.pids.length && (await this.getStationsByProjects(this.accraditation.pids));
      this.accraditation.stationIds = this.userRoleStations.map(({ stId }) => stId);
    },

    handleClose() {
      this.drawer = false;
    },

    //重置新建工单表单
    resetAccraditation(formName) {
      this.$refs[formName].resetFields();
      this.accraditation = {
        taskName: '',
        cpIds: this.cpIds,
        pids: [],
        stationIds: [],
        roles: [],
        approvalUserMobile: '',
        taskDescription: '',
      };
      this.dialog = false;
    },

    //拼接工单名称
    jointWorkName() {
      const tmp = {};
      tmp.cpId = this.userRoleCors.filter((corporation) => this.accraditation.cpIds[0] === corporation.cpId);
      tmp.project = this.userRolePros.filter((project) => this.accraditation.pids[0] === project.pId);
      tmp.site = this.userRoleStations.filter((site) => this.accraditation.stationIds[0] === site.stId);
      tmp.role = this.userRoles.filter((role) => this.accraditation.roles[0] === role.roleId);
      return tmp.role[0]?.roleName === '企业管理员' ? `申请-${tmp.cpId[0] ? tmp.cpId[0].cpName : ''}下${tmp.role[0] ? tmp.role[0]?.roleName : ''}` : `申请${tmp.project[0] ? tmp.project[0].pName : ''}-${tmp.site[0] ? tmp.site[0]?.sName : ''}-${tmp.role[0] ? tmp.role[0]?.roleName : ''}`;
    },

    //提交工单数据
    submitAccraditation(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.accraditation.taskName = this.jointWorkName();
          //TODO 编辑工单
          if (this.isEditWorkItem) {
            const params = JSON.parse(JSON.stringify(this.accraditation));
            params.taskId = this.accraditationDetails.taskId;
            const { code, reason } = await updateApprovalTask(params);
            if (!statusInclude(code)) return this.$message.error(`修改工单失败：${reason}`);
            this.resetAccraditation(formName);
            this.getMyApprovalTaskList({ ...this.tableConfig });
            this.$message({ type: 'success', message: '工单修改成功' });
          } else {
            const { code, reason } = await addApprovalTask(this.accraditation);
            if (!statusInclude(code)) return this.$message.error(`创建工单失败：${reason}`);
            this.resetAccraditation(formName);
            this.getMyApprovalTaskList({ ...this.tableConfig });
            this.$message({ type: 'success', message: '工单创建成功' });
          }
        }
      });
    },
    //撤销我的工单
    async undoApproval(taskId) {
      const { code, reason } = await undoMyApprovalTask(taskId);
      if (!statusInclude(code)) return this.$message.error(`操作失败：${reason}`);
      this.$message({ type: 'success', message: '操作成功' });
      this.getMyApprovalTaskList({ page: this.page, size: this.size });
    },

    //表格高度请求所需要请求的数据size
    async getSourceSize(time) {
      await sleep(time);
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51);
    },

    //修改查询的工单类型
    handleChangeApprovalStatus(work) {
      this.getMyApprovalTaskList({ ...this.tableConfig, ...work });
    },

    async handleSelect(work) {
      this.getMyApprovalTaskList({ ...this.tableConfig, ...work });
    },

    async handleCorporations(cpIds) {
      const [role] = this.accraditation.roles;
      if (role !== 'R0001' && role !== 'R0002' && role !== 'R0003') {
        const projects = await this.getProjectBycpIds(cpIds);
        this.accraditation.pids = projects.reduce((per, cur) => per.concat(cur.pId), []);
        const sites = await this.getStationsByProjects(this.accraditation.pids);
        this.accraditation.stationIds = sites.reduce((per, cur) => per.concat(cur.stId), []);
      }
    },
    async handleProjects(pIds) {
      const sites = await this.getStationsByProjects(pIds);

      const [role] = this.accraditation.roles;
      if (role === 'R0003') {
        this.accraditation.stationIds = sites.reduce((per, cur) => per.concat(cur.stId), []);
      }
    },

    //获取用户角色列表
    async getRoleGroup() {
      const { code, reason, data } = await getUserRole();
      if (!statusInclude(code)) return this.$message.error(`请求用户角色信息失败：${reason}`);
      this.userRoles = data;
    },

    async handleAccraditationDetails(taskId) {
      const { code, reason, data } = await getApprovalTaskDetail(taskId);
      if (!statusInclude(code)) return this.$message.error(`请求失败${reason}`);
      this.accraditationDetails = data;
      this.drawer = true;
    },
    async getMyApprovalTaskList({ page = 1, size = 14, name = '', status = '' }) {
      try {
        const { code, reason, data } = await myApprovalTaskList(page, size, name, status);
        if (!statusInclude(code)) return this.$message.error(`请求失败${reason}`);
        this.workList = data.records;
        this.total = data.total;
      } catch (err) {
        throw new Error(err);
      }
    },
    async undoMyApprovalTask() {},

    //请求用户企业信息
    getCorporationBySelf(mobile = '') {
      this.accraditation.approvalUserMobile = mobile ? mobile : ''; //用户联系方式
      return new Promise(async (resolve, reject) => {
        try {
          const { code, reason, data: corporation } = await getCorporationBySelf(mobile);
          if (!statusInclude(code)) {
            reject(reason);
            return this.$message.error(`请求用户企业信息失败${reason}`);
          }
          this.userRoleCors = corporation;
          resolve(corporation);
        } catch (err) {
          reject(err);
        }
      });
    },

    async getProjectBycpIds(cpIds) {
      const { code, reason, data } = await getProjectBycpIds(cpIds);
      if (!statusInclude(code)) return this.$message.error(`请求用户项目信息失败：${reason}`);
      this.userRolePros = data;
      return data;
    },
    async getStationsByProjects(pIds) {
      const { code, reason, data } = await getStationsByProjects(pIds);
      if (!statusInclude(code)) return this.$message.error(`请求用户信息失败：${reason}`);
      this.userRoleStations = data;
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.work-list-page-wrapper {
  .work-list-page {
    background: #{$cardBg};
    position: relative;
    ::v-deep .el-card__header {
      background: #{$cardHeaderBg};
      .el-form {
        .el-form-item {
          // &.input-with-search {
          //   .el-input {
          //     .el-input-group__append {
          //       background: #313131;
          //       border: none;
          //       > .el-button {
          //         height: 32px;
          //         border-radius: 0px;
          //         border: 0px;
          //         border: 1px solid #{$borderSolid};
          //         border-left: 0;
          //         background-color: #18273d;
          //       }
          //     }
          //     .el-input-group__prepend {
          //       border: none;
          //     }
          //   }
          // }
        }
      }
    }
    ::v-deep .el-card__body {
      height: calc(100vh - #{$offset});
      position: relative;
    }
  }
  ::v-deep .el-drawer__wrapper {
    .el-drawer__container {
      .el-drawer {
        padding: 20px;
        background: #{$tabsBg};
        border-color: #{$borderSolid};
        & .el-drawer__header {
          padding: 0;
          color: #5e83f5;
        }
        .el-drawer__body {
          .el-form {
            height: 100%;
            color: #f8f9fa;
            .el-form-item {
              &.role-form-item {
                height: calc(100vh - 220px);
                .el-form-item__content {
                  height: calc(100% - 60px);
                  overflow: auto;
                  // .el-tree {
                  //   height: 100%;
                  //   .el-tree-node {
                  //     &.is-expanded {
                  //       .el-tree-node__children {
                  //         height: calc(100%-300px);
                  //       }
                  //     }
                  //   }
                  // }
                }
              }
            }
            .el-form-item:first-child {
              height: 90px;
            }
          }
        }
      }
    }
  }
}
</style>
