<template>
  <ul class="accraditation-details-wrapper">
    <li class="accraditation-item">
      <strong>工单编号：</strong>
      <span>{{ accraditationDetails.taskId }}</span>
    </li>
    <li class="accraditation-item">
      <strong>工单名称：</strong>
      <span>{{ accraditationDetails.taskName }}</span>
    </li>
    <li class="accraditation-item">
      <strong>工单描述：</strong>
      <span>{{ accraditationDetails.taskDescription }}</span>
    </li>
    <li class="accraditation-item">
      <strong>审批人用户编码：</strong>
      <span>{{ accraditationDetails.taskStatus }}</span>
    </li>
    <li class="accraditation-item">
      <strong>任务反馈意见：</strong>
      <span>{{ accraditationDetails.approvalOpinions }}</span>
    </li>
    <li class="accraditation-item">
      <strong>审批人用户编码：</strong>
      <span>{{ accraditationDetails.approvalUserId }}</span>
    </li>
    <li class="accraditation-item">
      <strong>审批用户名称：</strong>
      <span>{{ accraditationDetails.approvalUserName }}</span>
    </li>
    <li class="accraditation-item">
      <strong>审批时间：</strong>
      <span>{{ accraditationDetails.approvalTime }}</span>
    </li>
    <li class="accraditation-item">
      <strong>父任务编号：</strong>
      <span>{{ accraditationDetails.parentTaskId }}</span>
    </li>
    <li class="accraditation-item">
      <strong>备注：</strong>
      <span>{{ accraditationDetails.remark }}</span>
    </li>
    <li class="accraditation-item">
      <strong>创建人编号：</strong>
      <span>{{ accraditationDetails.createUserId }}</span>
    </li>
    <li class="accraditation-item">
      <strong>创建人名称：</strong>
      <span>{{ accraditationDetails.createUserName }}</span>
    </li>
    <li class="accraditation-item">
      <strong>创建时间：</strong>
      <span>{{ $moment(accraditationDetails.createTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
    </li>
    <li class="accraditation-item" v-if="false">
      <strong>子任务：</strong>
      <span>{{ accraditationDetails.childTasks }}</span>
    </li>
    <li class="accraditation-item role-info">
      <strong>角色列表：</strong>
      <ul class="role-list">
        <li class="role-item" v-for="role in accraditationDetails.roles" :key="role.roleId">
          <strong>角色名称：</strong>
          <span>{{ role.roleName }}</span>
        </li>
      </ul>
    </li>
    <li class="accraditation-item corporation-info">
      <strong>企业列表：</strong>
    </li>
    <el-collapse accordion>
      <el-collapse-item :title="`公司：${corporation.cpName}`" :name="corporation.cpId" v-for="corporation in accraditationDetails.corporationInfos" :key="corporation.cpId">
        <el-collapse accordion>
          <el-collapse-item :title="`项目：${project.pName}`" :name="project.pId" v-for="project in corporation.projects" :key="project.pId">
            <ul class="site-list">
              <li v-for="site in project.stations" :key="site.stId" class="site-item">
                <strong>站点名称</strong>
                <span>{{ site.sName }}</span>
              </li>
            </ul>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>
  </ul>
</template>

<script>
export default {
  name: 'AccraditationDetails',
  props: {
    accraditationDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.accraditation-details-wrapper {
  padding: 0;
  margin: 0;
  li {
    &.accraditation-item {
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #fff;
      strong {
        color: #ddd;
        margin-right: 10px;
      }
      span {
        // display: inline-block;
        // flex: 1;
        padding-right: 10px;
      }
      &.role-info {
        max-height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .role-list {
          padding-left: 80px;
          .role-item {
            padding: 4px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
      &.corporation-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        // align-items: flex-start;
        .corporation-list {
          padding-left: 0px;
          .corporation-item {
            padding: 4px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .project-list {
              padding: 4px 0;
              display: flex;
              flex-direction: row;
              // justify-content: space-between;
              // align-items: center;
            }
          }
        }
      }
    }
  }
  & .el-collapse {
    border-color: #{$borderSolid};
    .el-collapse-item {
      div[role='tab'] {
        .el-collapse-item__header {
          background: #{$tHeaderBg};
          padding: 0 20px;
          color: #fff;
        }
      }
      .el-collapse-item__content {
        padding: 0;
        background: #{$menuHover};
        color: #eaf1f7;
        .site-list {
          .site-item {
            padding: 10px;
          }
        }
      }
      .el-collapse-item__wrap {
        border-color: #{$borderSolid};
      }
    }
  }
}
</style>
